<template>

	<div>
		<ContentTitle>通行记录</ContentTitle>
		<Wfilter :filterConfig="filterConfig" @handleSearch="handleSearch" @changeProject="changeProject"
			@changeCar="changeCar" @groupSearch="groupSearch"></Wfilter>
		<Wtable :columns="columns" :showCheck="showCheck" @playVideo="playVideo" :controlCol='true' :pagination="pagination"
			:tableData="tableData" :btns="btns" @currentChange="currentChange"
			@handleSelectionChange="handleSelectionChange">
		</Wtable>
	</div>
</template>

<script>
	import {
		ref
	} from "vue";
	import Wtable from "@/components/wTable/wTable.vue";
	import Wfilter from "@/components/wFilter/wFilter.vue";
	import ContentTitle from "@/components/contentTitle/contentTitle.vue";
	import config from "./index.json";
	export default {
		components: {
			Wtable,
			Wfilter,
			ContentTitle,
		},
		setup() {
			const columns = config.tableConfig.list;
			const filterConfig = config.filterConfig;
			const tableData = ref([{
					parking: "cesd001",
					parkingType: "赛车",
					card: "ICD22225",
					location: "道闸的位置1",
					direction: "出口",
					time: "2021/12/02 14:22:33",
					parkingTime: "2小时17分钟",
					carType: "临时车",
					abnormalTraffic: "-",
					noEntry: "是",
				},
				{
					parking: "cesd002",
					imgs: [
						"https://t7.baidu.com/it/u=1951548898,3927145&fm=193&f=GIF",
						"https://t7.baidu.com/it/u=2582370511,530426427&fm=193&f=GIF",
					],
					parkingType: "三轮",
					card: "ICD22225",
					location: "道闸的位置2",
					direction: "入口",
					time: "2021/12/02 14:22:33",
					parkingTime: "2小时17分钟",
					carType: "临时车",
					abnormalTraffic: "-",
					noEntry: "否",
				},
				{
					parking: "cesd003",
					parkingType: "小轿车",
					card: "ICD22225",
					location: "道闸的位置3",
					direction: "出口",
					time: "2021/12/02 14:22:33",
					parkingTime: "2小时17分钟",
					carType: "包月车",
					abnormalTraffic: "-",
					noEntry: "是",
					color: "var(--theme-color)",
				},
			]);

			const pagination = config.tableConfig.pagination;
			const showCheck = config.tableConfig.showCheck;
			/**
			 * 分页数据
			 * */
			const currentChange = (page) => {
				console.log(page);
			};

			function handleSearch() {}

			function changeProject() {}

			function changeCar() {}

			function playVideo(e) {
				console.log(e);
			}


			function handleSelectionChange(c) {
				console.log('c====', c);
			}
			const btns = ref([{
				lable: '批量下发'
			}])

			/**
			 * 组合查询
			 * */
			function groupSearch(data) {
				console.log('组合查询====', data);
			}
			return {
				groupSearch,
				btns,
				handleSelectionChange,
				showCheck,
				playVideo,
				tableData,
				columns,
				pagination,
				currentChange,
				filterConfig,
				handleSearch,
				changeProject,
				changeCar,
			};
		},
	};
</script>

<style>
</style>
